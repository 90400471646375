<template>
	<div class="mobphone">
		<div class="index-top">
			<div class="index-title">
				<div class="title">绑定手机号</div>
			</div>
			<div class="index-prompt">
				<div class="text">请绑定手机号，方便与您联系</div>
			</div>
		</div>
		<div class="mobphone-main">
			<div class="gongdan">
				<div class="dan">
					<div class="text"><span>*</span>手机号:</div>
					<div class="inputbox">
						<el-input v-model="Phoneinput" placeholder="请输入手机号"></el-input>
					</div>
				</div>
				<div class="dan yzm">
					<div class="text"><span>*</span>验证码:</div>
					<div class="inputbox">
						<el-input v-model="Codeinput" placeholder="请输入验证码"></el-input>
						<el-button type="primary" @click="toCode" :disabled="delenteAnniu" plain round>{{counditem}}</el-button>
					</div>
				</div>
				<div class="dan anniu">
					<el-checkbox v-model="checked">绑定手机号实时推送微信消息、解锁工具箱功能</el-checkbox>
				</div>
				<div class="dan anniu">
					<el-button type="primary" @click="onSubmit">提交绑定</el-button>
				</div>
			</div>
			<div class="enderQueren">
			</div>
		</div>
	</div>
</template>

<script>
export default {
	data () {
		return {
			delenteAnniu:false,
			Phoneinput:'',//手机号
			Codeinput:'',//验证码
			counditem:"发送验证码",
			daojishi:60,
			jishiqi:null,
			checked: false
		}
	},

	mounted(){
		if(!this._isMobile){
			window.location.href = '/mobphone';
		}
		console.log(this.$route)
	},

	methods: {
		// 测试用
		// onceshi(){
		// 	this.$service.get(this.$api.getWxpublicArt,'', headers, (res)=> {
		// 		console.log(123)
		// 		if(res.code == '200'){

		// 		}
		// 	})
		// },
		//发送验证码
		toCode(){
			if(!(/^1(3|4|5|6|7|8|9)\d{9}$/.test(this.Phoneinput))){
				this.$message({message: '请正确输入手机号',type: 'warning'});
			}else{
				var param = {
					account:this.Phoneinput,
				}
				this.$service.get(this.$api.sendMobileMsg,param, (res)=> {
					if(res.code == '200'){
						this.delenteAnniu = true
						this.jishiqi = setInterval(() => {
							this.daojishi = this.daojishi - 1
							this.counditem = this.daojishi + 's后重新发送'
							console.log(this.daojishi)
							if(this.daojishi == 0){
								clearInterval(this.jishiqi)
								this.delenteAnniu = false
								this.counditem = '发送验证码'
								this.daojishi = 60
							}
						}, 1000);
					}
				})
			}
		},
		//提交绑定手机号
		onSubmit(){
			if(this.Phoneinput == ''){this.$message({message: '请输入手机号',type: 'warning'});return}
			if(this.Codeinput == ''){this.$message({message: '请输入验证码',type: 'warning'});return}
			if(!this.checked){this.$message({message: '请确认推送微信消息、解锁工具箱功能',type: 'warning'});return}
			var param = {
				account:this.Phoneinput,
				sms_code:this.Codeinput,
				// token:this.$route.
			}
			this.$service.post(this.$api.bindMobile,param, (res)=> {
				if(res.code == '200'){
					this.$message({message: '绑定成功',type: 'warning'})
					setTimeout(() => {
                    	window.location.href = '/workList';
					}, 1000);
				}
			})
		}
	}
}
</script>

<style lang="scss" scoped>
.mobphone{
	text-align: center;
	.index-top{
		text-align: center;
		.index-title{
			align-items: center;
			justify-content: center;
		}
		.index-prompt{
			align-items: center;
			justify-content: center;
		}
	}
	.mobphone-main{
		margin-top: 20px;
		.gongdan{
			width: 400px;
			margin: 0 auto;
			.dan{
				width: 400px;
				.text{
					min-width: auto;
				}
				.el-input{
					margin-left: 10px;
				}
			}
			.yzm{
				.inputbox{
					display: flex;
					.el-input{
						width: 150px;
						margin-right: 10px;
					}
				}
			}
			.anniu{
				display: block;
				text-align: center;
			}
		}
	}
}
@media screen and (max-width: 750px) {
	.mobphone{
		.mobphone-main{
			.gongdan{
				width: 100%;
				.dan{
					justify-content: left;
					.text{
						width: 1.5rem;
					}
					.inputbox{
						width: 5.4rem;
						.el-input{
							margin-left: 0;
						}
					}
				}
			}
		}
	}
}
</style>
